import { navLinkData } from "./data";

// Languages List
const langBtn = document.querySelector(".languages-btn");
const langDropList = document.querySelector(".languages-droplist");

if (langBtn && langDropList) {
    langBtn.addEventListener("click", (event) => {
    if (langDropList.style.visibility !== "visible") {
        langDropList.style.visibility = "visible";
        event.stopPropagation();

        window.onclick = (e) => {
        if (e.target == langDropList || langDropList.contains(e.target)) {
            return;
        }
        langDropList.style.visibility = "hidden";
        window.onclick = null;
        };
        
        langDropList.focus();
    }
    });
}


// User's language recognition
export const recognizeUserLang = (setLanguage) => {
    const langList = ["ru", "en", "uk"];
    let userLang = "en";
    const userNavLang = navigator.language || navigator.userLanguage;
    const hashLang = window.location.hash.substring(1);

    if (langList.includes(hashLang)) {
        userLang = hashLang;
    } else {
        if (userNavLang.startsWith("ru")) {
            userLang = "ru";
        } else if (userNavLang.startsWith("uk")) {
            userLang = "uk"
        } else {
            userLang = "en";
        }

        location.hash = userLang;
    }

    document.querySelectorAll('a').forEach(val => {
		if (langList.includes(val.href.split('#').pop())) {
			val.addEventListener('click', () => {
				window.location.hash = val.href.split('#').pop();
			});
		};

		if (val.closest('nav#nav-pages') !== null || val.id === 'logo-link') {
			let pathSplit = val.pathname.split('#')
            val.href = pathSplit[0] + `#${userLang}`
		};
	});

    for (let key in navLinkData) {
        const elem = document.getElementById(`${key}`)
        if (elem) {
            elem.textContent = navLinkData[key][userLang];
        }
	};

    setLanguage(userLang)

    window.onhashchange = () => {
        recognizeUserLang(setLanguage)
    }
}
